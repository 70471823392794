<template>
  <div id="app">
    <v-overlay :z-index="999999" :value="show">
      <v-progress-circular
        indeterminate
        color="#FFD600"
        width="6"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "ShowOverlay",
  props: {
     value: Boolean
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
};
</script>